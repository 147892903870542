.MyHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 10;
  min-width: 1200px;
  height: 110px;
  padding: 0 30px 0 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #1a1b1f;
  background-color: #1a1b1f;

  font-family: "Gowun Dodum", sans-serif;
}

.MyHeader .head_title,
.MyHeader .head_search,
.MyHeader .head_menu {
  display: flex;
}
.head_title {
  width: 10%;
  justify-content: start;
}
.MyHeader .logo_img {
  max-width: 260px;
  margin-top: 50px;
}
.head_search {
  margin-top: 20px;
  width: 70%;
  justify-content: center;
}
.suggestion {
  color: #e75c5c;
  width: 100%;
  background-color: white;
  justify-content: center;
  cursor: pointer;
}
.suggestion_item {
  padding: 5px;
  margin-bottom: 5px;
}
.suggestion_item.highlighted {
  background-color: #e2e2e2;
  border-radius: 5px;
}
.head_search_box {
  position: fixed;
  margin-left: 15%;
  background-color: white;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2px;
  /* padding-bottom: 5px; */
  width: 50%;
  min-width: 500px;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

  border: 1px solid white;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.head_search_input_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.head_search_box input {
  margin-left: 10px;
  border: none;
  font-size: 16px;
  width: 100%;
  font-family: "Gowun Dodum", sans-serif;
}
.head_search_box input:focus {
  outline: none;
}
.head_search_box input:focus::placeholder {
  color: transparent;
}
.head_search_box button {
  border: none;
  background-color: white;
  cursor: pointer;
}
.head_menu {
  width: 20%;
  justify-content: end;
}
.head_menu ul {
  display: flex;
  margin: 30px 0;
  padding-top: 50px;
}
.head_menu li {
  width: 58px;
  list-style-type: none;
  /* padding: 5px 9px; */
  text-align: center;
}
.head_menu a {
  display: grid;
  place-items: center;
  color: #f7f7f7;
  font-size: 12px;
  text-decoration: none;
}
/* @media (max-width: 1024px) {
  .MyHeader .head_search {
    display: none;
  }
  .MyHeader .head_title {
    width: 50%;
    justify-content: start;
  }
  .head_menu {
    width: 50%;
    justify-content: end;
  }
} */
