.BestItem .categoryBtn ul {
  display: flex;
  margin: 0;
}
.BestItem .categoryBtn li {
  list-style-type: none;
  margin-right: 20px;
}

.BestItem .categoryBtn button {
  color: black;
  font-size: 16px;
  /* font-weight: bold; */
  background-color: white;
  padding: 5px 22px;
  border: 2px solid rgb(187, 187, 187);
  border-radius: 16px;

  cursor: pointer;
}
.BestItem .categoryBtn button:hover {
  color: rgb(241, 76, 172);
  border: 2px solid rgb(255, 187, 215);
  border-radius: 16px;
  transition: color 0.5s ease, border-color 0.5s ease;
  cursor: pointer;
}
.BestItem .categoryBtn button.selected:hover {
  border: 2px solid rgb(255, 175, 208);
  border-radius: 16px;

  cursor: pointer;
}
.BestItem .categoryBtn button.selected {
  font-weight: bold;
  border: 2px solid black;
}
.BestItem .item_dummy {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 375px);
  justify-items: center;
}
.BestItem .item_dummy > div {
  justify-content: center;
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  overflow: hidden;
}

.background-image {
  position: absolute;
  width: 400px; /* Adjust size as needed */
  height: auto;
  opacity: 0.5; /* Adjust transparency if needed */
}
