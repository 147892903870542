.Menu {
  position: fixed;
  top: 111px;
  left: 0;
  /* height: 0px; */
  float: inline-start;
  z-index: 9;
  /* animation: menu-ani 1.3s ease forwards; */
  width: 100%;
  min-width: 1000px;
}
.Menu .container {
  background-color: #1a1b1f;
  border-bottom: 1px solid #1a1b1f;
  border: 1px solid #1a1b1f;
}
.Menu ul {
  margin: 0;
}
.Menu ul li {
  margin: 10px 45px;
  list-style-type: none;
}
.Menu ul li a {
  text-decoration-line: none;
  color: rgb(190, 190, 190);
}
.Menu ul li a span {
  color: rgb(190, 190, 190);
}
.Menu .menu_main {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
.Menu li .menu-item {
  color: rgb(190, 190, 190);
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  cursor: pointer;
}
.Menu li .menu-item.selected {
  color: #f7f7f7;
  font-weight: bold;
  border-top: 3px solid #f7f7f7;
}

/* MenuList */
.MenuList {
  font-size: 17px;
  background-color: rgba(255, 255, 255, 0.562);
  position: sticky; /* 추가 */
  top: 0; /* 추가: 화면의 맨 위에 고정 */
  z-index: 1000;
}
.MenuList .category_box {
  display: flex;
  justify-content: center;
  margin: 0 20px;
}
.MenuList nav {
  padding: 20px;
}
.MenuList nav ul {
  margin: 0;
  padding: 0;
}
.MenuList nav ul li.title {
  font-weight: bold;
  margin: 0 40px;
}
.MenuList nav ul li {
  margin: 5px 0;
  list-style-type: none;
  width: 100px;
}
.MenuList nav ul li a {
  color: black;
}

.MenuList ul li a:hover {
  font-weight: bold;
  color: #9b3434;
}
.MenuList nav ul.subcategory {
  font-weight: lighter;
  margin: 10px 0;
}
.MenuList nav ul.subcategory li {
  margin-right: 10px;
}
