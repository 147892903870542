.PeriodSelector {
}
.PeriodSelector .content {
  width: 100%;
  height: 80px;

  border-bottom: 1px solid #161616;

  display: flex;
  justify-content: flex-start; /*start*/
  align-items: center;
}
.PeriodSelector .content .title {
  font-weight: bold;
  padding: 120px;
  text-align: center;
}
.PeriodSelector label {
  padding-right: 40px;
}
.PeriodSelector label input {
  margin-right: 10px;
}
