table {
  width: 100%;
  border-collapse: collapse;
}
table thead tr {
  border-bottom: 1px solid black;
  border-top: 3px solid black;
  text-align: center;
}
table tbody td {
}
table thead th {
  padding: 15px 0;
  text-align: center;
}
table td {
  text-align: center;
  padding: 10px;
}

.pagination {
  min-width: 1300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* OrderHistory */
.OrderHistory {
  margin: 0 auto;
}

.OrderHistory .lookup_box {
  width: 100%;
  border: 1px solid #161616;
  border-radius: 10px;
}
.OrderHistory .select_box {
  margin-top: 20px;
  display: flex;
  width: 100%;
}
.OrderHistory .select_box > div {
  display: flex;
  padding: 10px;
}
.OrderHistory .select_box .select_status {
  width: 15%;
  justify-content: flex-start;
  align-items: center;
}

.OrderHistory .select_box .search {
  width: 85%;
  padding-right: 20px;
  justify-content: flex-end;
  align-items: center;
}
.OrderHistory select {
  width: 140px;
  padding: 7px 10px;

  border: 1px solid #b6b6b6;
  border-radius: 5px;
  background-color: #fff;
}
.OrderHistory option {
  padding: 5px 0;
}
.OrderHistory .search input {
  width: 300px;
  margin-right: 15px;
  padding: 10px;

  border: 1px solid #b6b6b6;
  border-radius: 5px;
  background-color: #fff;
}
/* .OrderHistory button {
  margin-right: 10px;
  padding: 7px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #e4e4e4;
} */
.OrderHistory button.btn {
  margin-left: 15px;
  padding: 6px 20px;

  color: white;
  background-color: rgb(134, 192, 231);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 8px;

  font-size: 15px;
  cursor: pointer;
}

/* ListTable */
.ListTable {
}
.ListTable .no-data div {
  text-align: center;
  padding: 80px;
  font-size: 18px;
  color: #888;
}
.ListTable table.Point div {
  padding: 20px 0;
}

.Point .total_point {
  margin: 10px;
  text-align: end;
  font-size: 20px;
}
.ListTable table {
  margin-top: 10px;
}
.ListTable table td {
  padding: 5px;
}
.ListTable .iteminfo {
  margin: 10px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 10px;
  min-width: 250px;
}
.ListTable .iteminfo.none {
  pointer-events: none; /* 포인터 이벤트 비활성화: 마우스 클릭 등 이벤트를 막습니다. */
  background-color: rgba(177, 177, 177, 0.5); /* 배경색 회색으로 설정 */
  color: #808080; /* 글자색 회색으로 설정 */
  border: 1px solid rgba(175, 175, 175, 0.867);
}

.ListTable .iteminfo .img {
  position: relative;
  overflow: hidden;
  margin-right: 20px;
  width: 90px;
  height: 90px;
}
.ListTable .iteminfo .img.stock-overlay {
  position: relative; /* 부모 요소 위치 설정 */
}

.ListTable .iteminfo .img.stock-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 반투명한 검은 배경 */
  z-index: 1; /* 다른 요소 위에 표시되도록 설정 */
}

.ListTable .iteminfo .img .stock-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
  text-align: center;
  padding: 20px;

  z-index: 2; /* 다른 요소 위에 표시되도록 설정 */
}
.ListTable .iteminfo img {
  width: 100%;
  height: 90px;
  display: block;
}
.ListTable a {
  color: black;
  text-decoration: none;
}
.ListTable .iteminfo .content {
  width: 30%;
  text-align: initial;
  /* width: 55%; */
}
.ListTable .iteminfo .content > div {
  padding: 1px 0;
}
.ListTable .iteminfo .content .content_box {
  display: flex;
}
.ListTable .iteminfo .content .content_box span {
  text-decoration: line-through;
  color: #808080;
}
.ListTable .iteminfo .content .content_box > div {
  padding-right: 9px;
}
.ListTable .count {
  width: 20%;
}
.ListTable .totalPrice {
  width: 20%;
  /* padding-left: 30px; */
}
.ListTable .delivery {
  text-align: initial;
  margin: 20px;
}

.ListTable .delivery > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 0;
}
.ListTable .delivery .icon {
  margin-right: 10px;
}

.ListTable table tbody tr {
  border-bottom: 1px solid #eee;
}
/* .ListTable .btn_box {
  margin: 15px;
  padding-bottom: 10px;
} */
.ListTable .item_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ListTable .order_state {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ListTable .state_title {
  width: 40%;
}
.ListTable .btn_warpper {
  width: 60%;
}
.ListTable .btn_warpper > div {
  margin: 10px;
}

.ListTable .btn_warpper button {
  /* margin-top: 10px; */
  min-width: 85px;
  border: 2px solid hsl(0, 85%, 82%);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  cursor: pointer;
  padding: 7px 10px;

  font-weight: bold;
  font-size: 13px;
}
.ListTable .btn_warpper button:active {
  box-shadow: none;
}
.ListTable select.reason {
  margin-top: 10px;
  padding: 2px 10px;
  border: 1px solid #b6b6b6;
  border-radius: 0;
  background-color: #fff;
}
.ListTable .date > div > div {
  padding: 2px;
}
.ListTable .review_btn {
  /* border: 1px solid hsl(0, 85%, 82%); */
  border: none;
  background-color: #a490ff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 1px;
  cursor: pointer;
  font-size: 12px;
}
.PonitTable .ListTable .date > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
