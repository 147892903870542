.Cart .none_cart_list {
  border: 1px solid #ddd;
  padding: 50px;
  margin: 10px 0;
  text-align: center;
  font-size: 17px;
}
.Cart .cart_list {
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;

  display: flex;
  justify-content: start;
  align-items: center;
}
.Cart .cart_list .title {
  font-weight: bold;
  margin-bottom: 10px;
}
.Cart .cart_list .info_content .info > div {
  margin-right: 15px;
  font-size: 17px;
}
.Cart .cart_list .info_content .info .price {
  text-decoration: line-through;
  color: #929292;
}
.Cart .cart_list .info_content .info .discountRate {
  color: rgb(220, 0, 0);
}
.Cart .info_price > div {
  font-size: 17px;
}

.Cart .cart_list .img {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-right: 17px;
}
.Cart .cart_list .img.stock-overlay {
  position: relative; /* 부모 요소 위치 설정 */
}

.Cart .cart_list .img.stock-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.5); /* 반투명한 검은 배경 */
  z-index: 1; /* 다른 요소 위에 표시되도록 설정 */
}

.Cart .cart_list .img .stock-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
  text-align: center;
  padding: 20px;

  z-index: 2; /* 다른 요소 위에 표시되도록 설정 */
}
.Cart .cart_list img {
  width: 80px;
  height: 80px;
  display: block;
}
.Cart .cart_list a {
  color: black;
  text-decoration: none;
}

.Cart .cart_list .img .checkbox_conta {
  margin: 0 30px;
}
.Cart input[type="checkbox"] {
  width: 25px; /* 너비 조정 */
  height: 25px; /* 높이 조정 */
  border: none;
  accent-color: rgb(255, 112, 30);
  border-radius: 5px; /* 모서리를 둥글게 */
  cursor: pointer;
}
.Cart .cart_list .info_content {
  width: 37%;
  padding-right: 50px;
}
.Cart .count_box {
  width: 35%;
  text-align: center;
}
.Cart .cart_list .info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.Cart .count {
  margin-right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Cart .count .counter {
  border: 1px solid #e2e2e2;
}
.Cart .count button {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: none;
  background-color: white;
}
.Cart .count input {
  font-size: 17px;
  font-weight: bold;
  width: 50px;
  height: 35px;
  text-align: center;
  border: none;
  outline: none;
}
.Cart .totalPrice {
  font-size: 17px;
  font-weight: bold;
}
.Cart .CartContainer {
  display: flex;
}
.Cart .CartContainer .Bill {
  width: 25%;
}
.Cart .CartContainer .CartList {
  width: 75%;
}
.Cart .check_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Cart .check_bar button {
  width: 130px;
  border: 1px solid #161616;
  padding: 7px 0;
  background-color: white;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

/* Bill */

.Bill {
  min-width: 350px;
  margin-left: 50px;
}
.BillSc {
  position: sticky;
  top: 200px; /* 화면 상단에서의 거리 */
  z-index: 3; /* 다른 요소보다 위에 표시되도록 설정 */
}

.Bill h3 {
  font-size: 22px;
}
.Bill .BillContent {
  padding: 0 40px;
  border: 1px solid #ddd;
}
.between {
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.between .content {
  font-size: 16px;
  font-weight: bold;
}
.Bill .total {
  border-top: 1px solid #ddd;
  padding: 10px 0;
}
.Bill .total .totalPrice {
  font-weight: bold;
  font-size: 24px;
  color: rgb(220, 0, 0);
}
.Bill .btn_warpper > button {
  width: 100%;
  height: 55px;
  font-size: 19px;
  color: white;
  background-color: rgb(236, 129, 129);
  border: none;
  cursor: pointer;
}
.CartList h3 {
  border-bottom: 2px solid #0c0c0c;
  margin: 0;
  padding: 20px 10px;
}
.Cart .input_container {
  padding-left: 50px;
  padding-right: 100px;
}
.input_container .greenbutton {
  background-color: rgb(114, 184, 108);
  color: rgb(253, 255, 233);

  padding: 8px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  font-size: 14px;
  margin-bottom: 10px;
}
.input_container .greenbutton:active {
  box-shadow: none;
}
.input_container span {
  padding: 0 8px;
}
.input_container select {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 7px;
}
.input_container label {
  padding-right: 30px;
}
.input_container .input[readonly] {
  background-color: #f0f0f0; /* 배경색을 회색으로 변경 */
  color: #666; /* 텍스트 색상을 회색으로 변경 */
  /* 기타 필요한 스타일 추가 */
}
.pointinput {
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
}
.point_box {
  margin: 15px 0;
  padding: 5px 20px;
  background-color: #fffaea;
  border-radius: 10px;
}
.useallbtn {
  color: rgb(253, 255, 233);
  margin: 0 10px;
  height: 35px;
  width: 90px;
  border: none;
  border-radius: 8px;
  background-color: rgb(114, 184, 108);
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.useallbtn:active {
  box-shadow: none;
}
.Cart .stock {
  margin-bottom: 10px;
}
.Cart .stock span {
  color: orangered;
  margin-right: 5px;
}
