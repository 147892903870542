.OrderList .none_cart_list {
  border: 1px solid #ddd;
  padding: 50px;
  margin: 10px 0;
  text-align: center;
  font-size: 17px;
}
.OrderList .cart_list {
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;

  display: flex;
  justify-content: start;
  align-items: center;
}
.OrderList .cart_list .title {
  font-weight: bold;
  margin-bottom: 10px;
}
.OrderList .cart_list .info_content .info > div {
  margin-right: 15px;
}
.OrderList .cart_list .info_content .info .price {
  text-decoration: line-through;
  color: #929292;
}
.OrderList .cart_list .info_content .info .discountRate {
  color: rgb(220, 0, 0);
}

.OrderList .cart_list .img {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-right: 17px;
}
.OrderList .cart_list .img.stock-overlay {
  position: relative; /* 부모 요소 위치 설정 */
}
.OrderList .cart_list .img.stock-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.5); /* 반투명한 검은 배경 */
  z-index: 1; /* 다른 요소 위에 표시되도록 설정 */
}
.OrderList .cart_list .img .stock-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
  text-align: center;
  padding: 20px;

  z-index: 2; /* 다른 요소 위에 표시되도록 설정 */
}
.OrderList .cart_list img {
  width: 80px;
  height: 80px;
  display: block;
}
.OrderList .cart_list a {
  color: black;
  text-decoration: none;
}

.OrderList .cart_list .img .checkbox_conta {
  margin: 0 30px;
}
.OrderList input[type="checkbox"] {
  width: 25px; /* 너비 조정 */
  height: 25px; /* 높이 조정 */
  border: none;
  accent-color: rgb(255, 112, 30);
  border-radius: 5px; /* 모서리를 둥글게 */
  cursor: pointer;
}
.OrderList .cart_list .info_content {
  width: 55%;
  padding-right: 50px;
}
.OrderList .count_box {
  width: 30%;
  text-align: center;
}
.OrderList .cart_list .info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.OrderList .count {
  margin-right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.OrderList .count .counter {
  border: 1px solid #e2e2e2;
}
.OrderList .count button {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: none;
  background-color: white;
}
