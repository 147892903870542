/* FOOTER */
.MyFooter {
  position: relative;
  margin: auto;
  height: 0px;
  width: 1550px;
}
footer {
  /* position: absolute;
    top: auto;
    left: 0; */
  background-color: white;
  margin-top: 50px;
  width: 100%;
  min-width: 1270px;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: start;
  border-top: 1px solid black;
}
footer > div {
  width: 20%;
}
footer .foot_3 p {
  font-size: 14px;
}
footer .icon_container {
  display: flex;
  justify-content: start;
  align-items: center;
}
footer .icon_container > div {
  padding: 10px 15px;
}
footer .icon_container > div > a {
  color: #1b1b1b;
}
