table {
  width: 100%;
  border-collapse: collapse;
}
table thead tr {
  border-bottom: 1px solid black;
  border-top: 3px solid black;
  text-align: center;
}

table thead th {
  padding: 15px 0;
  text-align: center;
}
table td {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.pagination {
  min-width: 1300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Mypage {
}
/* .mypage_content {
  position: relative;
  top: 220px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 1300px;
  margin-bottom: 300px;
} */
.mypage_menu ul {
  display: flex;
  margin-bottom: 0;
  padding: 0;
}
.mypage_menu li {
  width: 17%;
  height: 40px;

  letter-spacing: 1px;

  list-style-type: none;
  border: 1px solid #ccc;
  border-radius: 12px 12px 0 0;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  background-color: #eee;
  cursor: pointer;
}

.mypage_menu li.click {
  background-color: white;
  font-weight: bold;
}
.mypage_menu a {
  color: black;
  text-decoration: none;
}

/* Review */
.Review .review_menu ul {
  margin: 0;
  padding: 0;

  background-color: #ececec;
  letter-spacing: 2px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.Review .review_menu ul li {
  width: 100%;
  padding: 10px;
  border-bottom: none;
  list-style-type: none;
  border-top: 5px solid #ececec;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
}
.Review .review_menu ul li.clicked {
  border: 1px solid #999999;
  border-top: 5px solid #999999;
  background-color: white;
  border-bottom: none;
  font-weight: bold;
}

.ReviewWrite {
}
.ReviewWrite h2 {
  text-align: center;
  margin: 50px;
}
.ReviewWrite .iteminfo {
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ReviewWrite ul li {
  margin: 0;
  list-style-type: none;
}
.ReviewWrite table tbody tr {
  border-bottom: 1px solid #eee;
}

.ReviewMine {
}
.ReviewMine h2 {
  text-align: center;
  margin: 50px;
}
.ReviewMine .iteminfo {
  text-align: initial;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ReviewMine .iteminfo .price span {
  text-decoration: line-through;
}
.ReviewMine .iteminfo {
  padding: 0 30px;
}
.ReviewMine .iteminfo .img_box {
  padding-right: 10px;
  margin: 10px;
}

.ReviewMine .iteminfo .img_box {
  position: relative;
  overflow: hidden;
  margin-right: 20px;
  width: 100px;
  height: 100px;
}
.ReviewMine .iteminfo .img_box.stock-overlay {
  position: relative; /* 부모 요소 위치 설정 */
}

.ReviewMine .iteminfo .img_box.stock-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.5); /* 반투명한 검은 배경 */
  z-index: 1; /* 다른 요소 위에 표시되도록 설정 */
}

.ReviewMine .iteminfo .img_box .stock-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
  text-align: center;
  padding: 20px;

  z-index: 2; /* 다른 요소 위에 표시되도록 설정 */
}
.ReviewMine .iteminfo .img_box img {
  width: 100px;
  height: 100px;
  display: block;
}
.ReviewMine .iteminfo .detail_box > div {
  padding: 3px;
}
.ReviewMine .iteminfo .detail_box .detail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ReviewMine .iteminfo .detail_box .detail > div {
  padding-right: 10px;
}
.ReviewMine .date > div {
  padding: 3px;
}
.ReviewMine table {
  width: 100%;
  text-align: center;
}
.ReviewMine table tbody tr {
  border-bottom: 1px solid #eee;
}
.ReviewMine .button_warpper {
  display: flex;
  justify-content: center;
}

/* ReviewWrite */
.ReviewWrite {
}
.ReviewWriteBox .item_warpper {
  padding: 10px 20px;
  border-top: 3px solid #161616;
  border-bottom: 1px solid #161616;

  margin-bottom: 20px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ReviewWriteBox .item_warpper .item_name {
  padding-left: 30px;
}
.ReviewWriteBox h2 {
  margin-left: 15px;
  font-size: 20px;
}

.ReviewWriteBox .stars_warpper {
  padding-bottom: 5px;
  text-align: center;
  border-bottom: 1px dashed #ccc;
}
.ReviewWriteBox .stars_warpper .stars {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReviewWriteBox .stars_warpper .stars div {
  padding: 0 3px;
}
.ReviewWriteBox .content_warpper {
  padding: 20px 0;
  text-align: center;
}
.ReviewWriteBox input {
  width: 100%;
  height: 20px;
  padding: 10px;
  margin-top: 10px;

  border: 1px solid #dbdbdb;
  border-radius: 5px;
  font-size: 15px;
}
.ReviewWriteBox input:focus {
  border: 1px solid #ff8a8a;
  outline: none;
}
.ReviewWriteBox textarea {
  width: 100%;
  height: 200px;
  padding: 10px;
  margin-top: 10px;

  border: 1px solid #dbdbdb;
  resize: none; /* 크기 조절*/
  border-radius: 5px;
  font-size: 15px;
}
.ReviewWriteBox textarea:focus {
  border: 1px solid #ff8a8a;
  outline: none;
}
.ReviewWriteBox .button_warpper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.ReviewWriteBox button {
  margin-top: 0;
  font-size: 14px;
  width: 120px;
  height: 38px;
}
.ReviewWriteBox button:active {
  box-shadow: none;
}

.ReviewLookBox {
}
.ReviewLookBox h2 {
  margin-left: 15px;
  font-size: 20px;
}
.ReviewLookBox .item_warpper {
  padding: 5px 0;
  border-top: 3px solid #161616;
  border-bottom: 1px solid #161616;

  margin-bottom: 20px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ReviewLookBox .item_warpper .item_name {
  padding-left: 30px;
  height: 60px;
  display: grid;
  justify-content: center;
  align-items: center;
}
.ReviewLookBox .stars {
  margin-top: 30px;
  margin-right: 10px;
  text-align: end;
}

.ReviewLookBox .title {
  margin-top: 15px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}
.ReviewLookBox .content_warpper .content {
  padding-bottom: 20px;
}
.ReviewLookBox .content_warpper .content > div {
  background-color: #fdefef;
  border-radius: 10px;
  padding: 30px;
}

/* 1:1 문의를 위한 추가 사항*/
.ReviewMine .comment .waiting {
  /* font-weight: bold; */
}
.ReviewMine .comment .completed {
  font-weight: bold;
  color: #ff1515;
}
.ReviewMine .button_conta {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReviewMine .button_conta > div {
  padding: 0 10px;
}
.ReviewMine .title {
  font-weight: bold;
  padding: 7px;
}
.ReviewMine .content {
  padding: 7px;
}
.ReviewMine .no-data div {
  text-align: center;
  padding: 80px;
  font-size: 18px;
  color: #888;
}
.ReviewMine a {
  text-decoration: none;
  color: black;
}
.QandA .none_review_list {
  border: 1px solid #ddd;
  padding: 50px;
  margin: 10px 0;
  text-align: center;
  font-size: 17px;
}

.QandA h3 {
  border-bottom: 3px solid #0e0e0e;
  padding-bottom: 30px;
  margin: 0;
}
.QandA .review_list {
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;

  display: flex;
  justify-content: start;
  align-items: center;
}
.QandA .review_list .btn_box {
  width: 20%;
  text-align: center;
}
.QandA .review_list .btn_box > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.QandA .review_list .btn_box > div > div {
  margin: 10px;
}
/*  */
.QandA .review_list .info_content {
  width: 65%;
  padding-right: 50px;
}
.QandA .review_star_total .text {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0;
}
.QandA .review_list .info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.QandA .review_list .info > div {
  margin-top: 10px;
  padding: 0 20px;
  border-right: 1px solid #b4b4b4;
}
.QandA .review_list .info span {
  padding-right: 5px;
}
.QandA .review_list .review {
  padding: 0 20px;
  margin-top: 20px;
}
.QandA .review_list .review > div {
  margin-bottom: 12px;
}
.QandA .qacomment_box {
  background-color: #f0f0f0;
  margin-left: 20px;
  width: 93%;
  border-radius: 10px;
  padding: 20px;
}
.QandA .img {
  margin: 0 40px;
}
.QandA .img.stock-overlay {
  position: relative; /* 부모 요소 위치 설정 */
}

.QandA .img.stock-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 130px;
  background-color: rgba(0, 0, 0, 0.5); /* 반투명한 검은 배경 */
  z-index: 1; /* 다른 요소 위에 표시되도록 설정 */
}

.QandA .img .stock-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
  text-align: center;
  padding: 20px;

  z-index: 2; /* 다른 요소 위에 표시되도록 설정 */
}
.QandA img {
  width: 120px;
  height: 130px;
  display: block;
}
.QandA a {
  color: black;
  text-decoration: none;
}

.Membership .myinfo {
  text-align: center;
  margin: 20px;
}

.Membership .myinfo .grade {
  font-weight: bold;
  margin-bottom: 40px;
}
.Membership .myinfo .gradename {
  font-weight: lighter;
  font-size: 45px;
  margin: 15px;
  letter-spacing: 3px;
}

.Membership .myinfo .totalprice {
  font-size: 27px;
  font-weight: bold;
  margin-bottom: 50px;
  color: #ff2ea1;
}
.Membership table td {
  padding: 20px 0;
}
