.slider-container {
  position: relative;
  width: 100%;
  height: 300px;
  margin: auto;
  margin-bottom: 30px;
  overflow: hidden;
}

.slider-wrapper {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%; /* 슬라이더의 전체 너비를 100%로 설정 */
}

.slide {
  flex: 0 0 calc(100% / 3); /* 화면에 3개의 슬라이드가 보이도록 정확히 설정 */
  box-sizing: border-box;
}

.slide img {
  width: 100%;
  height: 300px;
  display: block;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #3d3d3d8c;
  color: white;
  border: none;
  padding: 0 10px 2px 10px;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
  z-index: 10;
  font-size: 30px;
}

.slider-button.prev {
  left: 0;
}

.slider-button.next {
  right: 0;
}

.slider-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #ddd;
}

.slider-progress-bar {
  height: 100%;
  background-color: #000000;
  transition: width 0.5s ease-in-out;
}
