.LoginHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 10;

  height: 125px;
  padding: 0 30px 0 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid black;
  background-color: white;
}

.LoginHeader > div {
  display: flex;
}
.LoginHeader .login_title {
  width: 60%;
  font-size: 20px;
  justify-content: center;
}
.LoginHeader .login_back {
  width: 20%;
  justify-content: start;
}
.LoginHeader .login_home {
  width: 20%;
  justify-content: end;
}
.LoginHeader button {
  border: none;
  background-color: white;
}
