.UserGrade {
}
.usergrade_content {
  border: 1px solid #ddd;
  height: 180px;

  background-color: #0f0f0f;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;
}
.usergrade_content p {
  color: white;
}
.usergrade_content div {
  color: white;
}
.usergrade_title {
  width: 35%;
  padding: 30px;

  display: flex;
  justify-content: flex-start; /*start*/
  align-items: center;
}
.usergrade_title img {
  width: 80px;
}
.title_content {
  margin-left: 25px;
}
.title_content .goInfo {
  padding-left: 10px;
  color: gray;
  font-size: 13px;
  text-decoration: none;

  cursor: pointer;
}
.title_content strong {
  padding-right: 5px;
  font-size: 22px;
}
.usergrade_sub {
  width: 65%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.usergrade_sub .goPoint {
  width: 100%;
  height: 100%;

  border-left: 1px solid #ccc;
  color: white;
  text-decoration: none;
  display: grid;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  pointer-events: auto;
}

.usergrade_sub .icon {
  padding-top: 30px;
  text-align: center;
}
.usergrade_sub .contnet {
  text-align: center;
  font-size: 18px;
}
.usergrade_sub .numbers {
  font-weight: bold;
  padding-bottom: 30px;
  text-align: center;
  font-size: 25px;
}
