.DatePickerSelector {
}
.DatePickerSelector .content {
  width: 100%;
  height: 80px;

  border-bottom: 1px solid #161616;

  display: flex;
  justify-content: flex-start; /*start*/
  align-items: center;
}
.DatePickerSelector .content .title {
  font-weight: bold;
  padding: 120px;
  text-align: center;
}
.DatePickerSelector .search {
  margin-left: 30px;
  padding: 5px 20px;

  color: white;
  background-color: rgb(134, 192, 231);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 8px;

  font-size: 15px;
  cursor: pointer;
}
.DatePickerSelector button:active {
  box-shadow: none;
}
