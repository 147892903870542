.imageSlide {
  position: relative;
  top: 40px;
  width: 500px;
  margin: auto 50px;
  padding-bottom: 0px;

  .navBox {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 14px;
    z-index: 2;

    span {
      padding: 0 5px;
    }
  }

  .slideBox {
    position: relative;
    width: 100%;
    margin: auto;
    overflow-x: hidden;

    .slideList {
      width: 2800px;
      transition: all 300ms ease 0s;
      overflow: hidden;

      .slideContent {
        display: table;
        float: left;
        width: 500px;
        height: 500px;

        picture {
          display: table-cell;
          vertical-align: middle;
          text-align: center;

          img {
            width: 500px;
            height: 500px;
          }
        }
      }
    }

    &:hover .buttonPrev {
      left: 0;
      transition: left 0.5s;
    }

    &:hover .buttonNext {
      right: 0;
      transition: right 0.5s;
    }

    .buttonPrev,
    .buttonNext {
      position: absolute;
      top: 225px;
      width: 50px;
      height: 50px;
      padding-top: 5px;
      background-color: #333;
      font-size: 40px;
      font-weight: 100;
      vertical-align: middle;
      color: #eeeeee;
    }
    .buttonPrev {
      left: -50px;
    }
    .buttonNext {
      right: -50px;
    }
  }

  .paginationBox {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    grid-column-gap: 3px;

    picture {
      width: 100%;

      img {
        width: 100%;
        height: 130px;
        cursor: pointer;
      }
    }
  }
}
