.Login {
  margin-top: 220px;
}
.login_content {
  max-width: 500px;
  margin: 50px auto;
  padding: 50px 50px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login_box {
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start; /*start*/
  align-items: center;
}
.login_box input {
  margin-left: 5px;
  padding: 10px;
  border: none;
  width: 100%;
  font-size: 16px;
}
.login_box input:focus {
  outline: none;
}
.login_box input:focus::placeholder {
  color: transparent;
}
.login_content button {
  margin-top: 30px;
  padding: 15px 0;
  background-color: #161616;
  color: white;

  width: 100%;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  font-size: 16px;
  margin-bottom: 10px;
}
.login_content button:active {
  box-shadow: none;
}
.login_search {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_search span {
  padding: 0 20px;
}
.login_search a {
  color: #161616;
  text-decoration: none;
  font-weight: bold;
}

/* FindPw */
.login_content .findid_text {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_content .findid_text a {
  margin-left: 10px;
  color: black;
  font-weight: bold;
  text-decoration: none;
}
